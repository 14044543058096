export default {
  state: {
    steps: [],
  },
  getters: {
    getAllSteps: (s) => s.steps,
  },
  mutations: {
    getStepsMut(s, data) {
      s.steps = data;
    },
  },
  actions: {
    async getSteps(ctx) {
      return this.$axios({
        method: 'GET',
        url: `api/v1/steps/`,
      }).then((res) => {
        ctx.commit("getStepsMut", res.data);
      })
    },
    async setUserSteps(ctx, slug) {
      return this.$axios({
        method: 'GET',
        url: `api/v1/steps/${slug}/user-step/`,
      })
    },
   },
};
