export default {
    state: {
      test: {},
    },
    getters: {
      getTest: (s) => s.test,
    },
    mutations: {
      setTest(s, data) {
        s.test = data;
      },
    },
    actions: {
      async getTestBySlug(ctx, slug) {
        return this.$axios({
          method: 'GET',
          url: `api/v1/tests/${slug}/`,
          data: { slug: slug },
        })
          .then((result) => {
            // ctx.commit("setTest", result.data)
            return result.data
          })
          .catch((error) => {
            console.error(error)
            return false
          })
      },
      async sendTest(ctx, data) {
        return this.$axios({
          method: 'POST',
          url: `api/v1/test_result/`,
          data: data,
        })
      }
     },
  };
  